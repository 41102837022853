<template>
  <div>
    <a-card title="Posting Amortisasi">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <a-date-picker
                style="width: 125px;"
                v-model="tglposting"
                :allowClear="false"
                format="DD-MM-YYYY"
              />
      <a-button class="btn btn-outline-primary ml-2" @click="postingamor">Posting</a-button>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <a-table
        class="mt-3"
        style="margin: 0 -40px; margin-bottom: -25px;"
        :columns="columns"
        :dataSource="data"
        :pagination="{
          hideOnSinglePage: true,
          defaultPageSize: 10000000,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
          pageSizeOptions: ['10', '20', '30'],
        }"
        size="small"
        :loading="loading"
      >
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`Search ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            Search
          </a-button>
          <a-button
            size="small"
            style="width: 90px"
            @click="() => handleReset(clearFilters)"
          >
            Reset
          </a-button>
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        ></a-icon>
        <template slot="customRender" slot-scope="text, record, index, column">
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
            {{ text }}
          </template>
        </template>
        <span slot="action" slot-scope="text, record">
          <a class="text-danger" @click="confirmationDelete(record.tgl)">
            <a-icon type="delete"></a-icon>
          </a>
        </span>
        <span slot="tgl" slot-scope="text">
          {{ formatDate(text, 'YYYY-MM-DD') }}
        </span>
        <span slot="waktu_Posting" slot-scope="text">
          {{ formatDate(text, 'YYYY-MM-DDTHH:mm:ss', 'DD-MM-YYYY HH mm ss') }}
        </span>
            <span slot="formatCurrency" slot-scope="text">
              {{ formatCurrency(text) }}
            </span>
        <!-- <template
          :expandIconColumnIndex="3"
          slot="expandedRowRender"
          slot-scope="record"
          style="margin: 1"
        >
          <a-table
            :columns="columnChildren"
            :dataSource="record.details"
            size="small"
            :pagination="{
              hideOnSinglePage: true,
              defaultPageSize: 10000000,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
              pageSizeOptions: ['10', '20', '30'],
            }"
          >
            <span slot="formatCurrency" slot-scope="text">
              {{ formatCurrency(text) }}
            </span>
          </a-table>
        </template> -->
      </a-table>
      </div>
    </a-card>
  </div>
</template>

<script>
import { Modal } from 'ant-design-vue'
import moment from 'moment'
import * as lou from '@/services/data/lou'
export default {
  created () {
    this.getAllData()
  },
  data() {
    return {
      action: 'create',
      loading: false,
      theme: this.$store.state.settings.theme,
      tglposting: moment(),
      searchText: '',
      tgl1: moment(),
      searchInput: null,
      searchedColumn: '',
      data: [],
      columns: [
        {
          title: 'Action',
          scopedSlots: { customRender: 'action' },
          width: 70,
          // fixed: 'left',
        },
        {
          title: 'Faktur',
          dataIndex: 'faktur',
          width: 150,
          // fixed: 'left',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.faktur
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Tanggal Posting',
          dataIndex: 'tgl',
          width: 100,
          // fixed: 'left',
          scopedSlots: {
            customRender: 'tgl',
          },
        },
        {
          title: 'Nama',
          dataIndex: 'nama',
          width: 150,
          // fixed: 'left',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.nama
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Periode',
          dataIndex: 'periode',
          width: 150,
          // fixed: 'left',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.periode
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Waktu Posting',
          dataIndex: 'waktu_Posting',
          width: 100,
          // fixed: 'left',
          scopedSlots: {
            customRender: 'waktu_Posting',
          },
        },
      ],
    }
  },
  methods: {
    async getAllData() {
      this.loading = true
      var res = await lou.customUrlGet2('utility/posting/amortisasi')
      if (res) {
        this.data = res.data
        // console.log('this.data', this.data)
        setTimeout(() => {
          this.loading = false
        }, 100)
      } else {
        setTimeout(() => {
          this.loading = false
        }, 100)
      }
    },
    postingamor() {
      Modal.confirm({
        title: 'Anda yakin ingin memposting amortisasi?',
        content: 'Perilaku/Kejadian tidak dapat diubah!',
        okText: 'Yes',
        okType: 'primary',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.customUrlPost2('utility/posting/amortisasi?tglposting=' + this.tglposting.format('YYYY-MM-DD'))
          if (response) {
            this.getAllData()
            lou.shownotif('Success', 'Data telah berhasil di posting.', 'success')
          }
        },
        onCancel: () => {
          console.log('Cancel')
        },
      })
    },
    async confirmationDelete(key) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          await lou.customUrlDelete2('utility/posting/amortisasi?tglposting=' + key, true, false)
          this.getAllData()
        },
        onCancel: () => {
          console.log('Cancel')
        },
      })
    },
    formatDate(value, format = 'YYYY-MM-DD', toformat = 'DD-MM-YYYY') {
      return moment(value, format).format(toformat)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
